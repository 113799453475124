<template>
  <v-card-actions class="justify-center block pa-6 pb-16">
    <GoogleLogin
      class="google-login-btn"
      :client-id="clientId"
      :callback="callback"
    />
  </v-card-actions>
</template>

<script>
import { GoogleLogin } from "vue3-google-login";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    GoogleLogin,
  },
  emits: ["setUpNotice", "setUpLogoutTimer"],
  data() {
    return {
      message: "",
      clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
    };
  },
  computed: {
    ...mapGetters(["loggedIn"]),
  },
  methods: {
    ...mapActions(["loginByGoogleIdToken"]),
    callback(response) {
      this.loginByGoogleIdToken({
        id_token: response.credential
      }).then(() => {
        if(this.loggedIn == false){
          this.message = "あなたはログイン権限を保持していません";
          this.$emit("message-updated", this.message);
        } else {
          this.$emit("setUpNotice");
          this.$emit("setUpLogoutTimer");
        }
      }
      );
    },
  },
};
</script>
<style scoped>
.google-login-btn {
  transform: scale(1.5); /* 1.5倍に拡大 */
}
</style>
